import * as moment from 'moment';
import * as React from 'react';
import { ToolbarState } from '../globals/mainPage/mainPage';
import Restricted from '../permissions/Restricted';
import { CompanyStatusEvolution } from './companyStatusEvolution/CompanyStatusEvolution';
import { EventsPerCompany } from './eventsPerCompany/EventsPerCompany';
import { EventsPerUser } from './eventsPerUser/EventsPerUser';
import { MerchandisingPerCompany } from './merchandisingPerCompany/MerchandisingPerCompany';
import { ModalLeft } from './ModalLeft';
import { OrderPerProductPerCompany } from './orderPerProductPerCompany/OrderPerProductPerCompany';
import { OrdersEvolution } from './ordersEvolution/OrdersEvolution';
import { PresencePerProductPerCompany } from './presencePerProductPerCompany/PresencePerProductPerCompany';
import { PresencePerProducts } from './presencePerProducts/PresencePerProducts';
import { PresencePerUsers } from './presencePerUsers/PresencePerUsers';
import { initState, reducer, reportingAction, reportingState } from './Reducer';
import { PresencePerBrandOrCategoryPerCompany } from './presencePerBrandOrCategoryPerCompany/presencePerBrandOrCategoryPerCompany';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import { ModalState } from '../products/model';
import PresenceEvolution from './presenceEvolution/PresenceEvolution';
import PresenceEvolutionPerProduct from './presenceEvolutionPerProduct/PresenceEvolutionPerProduct';
import EventCountEvolutionPerCompany from './eventCountEvolutionPerCompany/eventCountEvolutionPerCompany';
import EventCountEvolutionPerUser from './eventCountEvolutionPerUser/eventCountEvolutionPerUser';
import MerchandisingEvolutionPerCompany from './merchandisingEvolutionPerCompany/merchandisingEvolutionPerCompany';
import MerchandisingEvolutionPerProduct from './merchandisingEvolutionPerProduct/merchandisingEvolutionPerProduct';
import MerchandisingPerCompanyPerProduct from './merchandisingPerCompanyPerProduct/merchandisingPerCompanyPerProduct';
import Generic from './generic/generic';
import PermissionContext from '../permissions/PermissionContext';
import { getReports, Report } from './action';
import { InstancePopup } from '../forms/formInstancePopup';
import { ReportInterpretorProviderLinksProps } from './interpretor/hoverContext';
import NoData from '../../containers_v2/noData/NoData';
import { translateToString } from '../../styles/global/translate';
import { NewReport } from './generic/generic';
import { AlertContext } from '../../containers_v2/alert/AlertProvider';

export interface SetSelectedReport {
	height: number
	isModalOpen: boolean
	setIsModalOpen: (isOpen: boolean) => void
	state: reportingState
	dispatch: (action: reportingAction) => void
	reports: Report[]
}

export function createDate(range) {
	if (!range) { return ''; }
	return (range.gteDate ? range.gteDate : '') + '_' + (range.lteDate ? moment(range.lteDate).add(1, 'days').format('YYYY-MM-DD') : '');
}

export function Reports(props: {
	updateToolbarState: (value: ToolbarState) => void
	setToolBarState: (value: ToolbarState) => void
}) {
	const [state, dispatch] = React.useReducer(reducer, initState());
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);
	const [companyModal, setCompanyModal] = React.useState<ModalState<number>>({ isOpen: false });
	const [formModalState, setFormModalState] = React.useState<ModalState<string>>({ isOpen: false });
	const [reports, setReports] = React.useState<Report[]>([]);
	const { innerWidth: width, innerHeight: height } = window;
	const padding = 1;
	const true_height = 98 - 100 / (height / padding);
	const marginLeft = isModalOpen ? 260 : 0;
	const { isAllowedTo } = React.useContext(PermissionContext);
	const isFormV2 = isAllowedTo({ objectAction: 'ViewForm' });
	const [noData, setNoData] = React.useState<boolean>(false);
	const { alert } = React.useContext(AlertContext);
	const updateGenericReports = async() => {
		if (isFormV2) {
			const r = await getReports();
			setReports(r);
			const i = r.find(e => e.id.toString() == state.selectedReport);
			if (i) {
				dispatch({ type: 'SET_SELECTED_REPORT', value: i.id.toString(), data: { ...i } });
			}
			else {
				const sortedReports = r.sort((a, b) => a.name.localeCompare(b.name));
				if (sortedReports.length > 0)
					dispatch({ type: 'SET_SELECTED_REPORT', value: sortedReports[0].id.toString(), data: { ...sortedReports[0] } });
				else {
					setNoData(true);
				}
			}
		}
	};

	React.useEffect(() => {
		if (isAllowedTo({ objectAction: 'ViewReporting' })) {
			updateGenericReports();
		}
	}, []);

	React.useEffect(() => {
		props.setToolBarState({ title: translateToString('reports') });
	}, []);

	const reportInterpretorProps: ReportInterpretorProviderLinksProps & {state: reportingState, dispatch: React.Dispatch<reportingAction> } = {
		state,
		dispatch,
		onFormInstanceClick: uuid => setFormModalState({ isOpen: true, data: uuid, fullOpenMode: true }),
		onCompanyClick: data => setCompanyModal({ isOpen: true, data }),
	};

	return (
		<Restricted to={{ objectAction: 'ViewReporting' }}>
			<div>
				<div style={{ overflow: 'hidden', marginTop: padding + 'px' }}>
					<ModalLeft
						height={true_height}
						isModalOpen={isModalOpen}
						setIsModalOpen={setIsModalOpen}
						state={state}
						dispatch={dispatch}
						reports={reports}
					/>
					<div style={{ marginLeft: `${marginLeft}px`, height: 'calc(100vh - 120px)', transition: '0.2s' }}>
						{state.selectedReport == 'merchandising_per_company_per_product' && <MerchandisingPerCompanyPerProduct
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.selectedReport == 'merchandising_evolution_per_product' && <MerchandisingEvolutionPerProduct
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.selectedReport == 'merchandising_evolution_per_companies' && <MerchandisingEvolutionPerCompany
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.selectedReport == 'event_count_evolution_per_user' && <EventCountEvolutionPerUser
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.selectedReport == 'event_count_evolution_per_companies' && <EventCountEvolutionPerCompany
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.data && <Generic
							reprasentation={state.data.value}
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
							updateGenericReports={updateGenericReports}
						/>}
						{state.selectedReport == 'presence_evolution_per_product' && <PresenceEvolutionPerProduct
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.selectedReport == 'presence_evolution' && <PresenceEvolution
							setToolBarState={props.updateToolbarState}
							{...reportInterpretorProps}
						/>}
						{state.selectedReport == 'presence_per_product_per_company' &&
							<PresencePerProductPerCompany
								setToolBarState={props.updateToolbarState}
								{...reportInterpretorProps}
							/>
						}
						{state.selectedReport == 'presence_per_brand_or_category_per_company' &&
							<PresencePerBrandOrCategoryPerCompany
								setToolBarState={props.updateToolbarState}
								{...reportInterpretorProps}
							/>
						}
						{state.selectedReport == 'merchandising_per_company' &&
							<MerchandisingPerCompany
								setToolBarState={props.updateToolbarState}
								{...reportInterpretorProps}
							/>
						}
						{state.selectedReport == 'events_per_company' &&
							<EventsPerCompany
								setToolBarState={props.updateToolbarState}
								{...reportInterpretorProps}
							/>
						}
						{state.selectedReport == 'events_per_user' &&
							<EventsPerUser
								setToolBarState={props.updateToolbarState}
								state={state}
								dispatch={dispatch}
							/>
						}
						{state.selectedReport == 'company_status_evolution' &&
							<CompanyStatusEvolution
								setToolBarState={props.updateToolbarState}
								state={state}
								dispatch={dispatch}
								width={width - marginLeft - 60}
								height={height - padding}
							/>
						}
						{state.selectedReport == 'presence_per_users' &&
							<PresencePerUsers
								setToolBarState={props.updateToolbarState}
								state={state}
								dispatch={dispatch}
								width={width - marginLeft - 60}
								height={height - padding}
							/>
						}
						{state.selectedReport == 'presence_per_products' &&
							<PresencePerProducts
								setToolBarState={props.updateToolbarState}
								state={state}
								dispatch={dispatch}
								width={width - marginLeft - 60}
								height={height - padding}
							/>
						}
						{state.selectedReport == 'orders_evolution' &&
							<OrdersEvolution
								setToolBarState={props.updateToolbarState}
								state={state}
								dispatch={dispatch}
								width={width - marginLeft - 60}
								height={height - padding}
							/>
						}
						{state.selectedReport == 'order_per_product_per_company' &&
							<OrderPerProductPerCompany
								setToolBarState={props.updateToolbarState}
								{...reportInterpretorProps}
							/>
						}
						{noData && <NoData height='100%' message={translateToString('reports.no_report')} messageButton={translateToString('reports.create_report')} onClickButton={() => {
							alert({
								title: translateToString('reports.name_report'),
								content: (onBreak, onOk) => <NewReport onBreak={onBreak} onOk={onOk} />,
								noButtons: true,
							});}} />}
					</div>
				</div>
			</div>
			<ClientCompany
				isOpen={companyModal.isOpen}
				setOpen={(isOpen) => setCompanyModal({ isOpen })}
				clientCompanyId={companyModal.data ?? 0}
			/>
			<InstancePopup modalState={formModalState} isOpen={formModalState.isOpen}
				onClickOut={() => setFormModalState({ ...formModalState, isOpen: false, })}/>
		</Restricted>
	);
}
