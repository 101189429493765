import * as React from 'react';
import { getLocalStorage } from '../../../helpers/localStorage';
import { Editor } from '@monaco-editor/react';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router';
import { LocalStorageReport } from './localReport';
import { getReportById, putReport } from '../action';
import { FlexDiv } from '../../products/style';
import styled from 'styled-components';
import { BlueSidely, LightGreySidely2, DarkGreySidely2 } from '../../../styles/global/css/Utils';
import { useRecoilValue } from 'recoil';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { RecursiveReportEditor } from './genericEditorRecursive';
import { GenericReport } from 'bindings/reports/generic/GenericReport';
import { Granularity } from 'bindings/time/Granularity';
import { useFunctionState } from '../../../utils/customHooks';
import { AProducts, Product } from '../../../atoms/product';
import Add from '../../../components_v2/add/Add';
import Popup from '../../../components_v2/popup/Popup';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import RemoveIcon from 'images/ui_icon/trash_red.svg';
import { TitleAndChild, OptionsModal, EditorWrapper } from '../../client-companies/popup/Detail';
import { assert } from '../../../utils/assert';
import { translateToString } from '../../../styles/global/translate';
import ReportInterpretor from '../interpretor/ReportInterpretor';
import { Report } from '../interpretor/bareReportingDecoder';
import { momentToNaiveDateTime, useWindowDimensions } from '../../../components_v2/utils';
import * as moment from 'moment';
import { getGenericAndDecodeReport } from './action';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { LoadingStateEnum } from '../../import/model';
import { GroupBy } from 'bindings/reports/generic/GroupBy';
import { Variation } from 'bindings/reports/generic/Variation';
import { ComponentProps } from '../../../containers_v2/globals/mainPage/mainPage';
import RestrictedSuperAdmin from '../../../containers_v2/permissions/RestrictedSuperAdmin';
import { CrossClose } from '../../../components_v2/popup/style/PopupCreationStyle';
import close from 'images/icons/orders/close.svg';
import { CrossImg } from './genericEditorRecursive';
import { ReportFiltersContext, ReportFiltersProvider, filterProducts, LocalFilters } from './generic';
import { AUsers } from '../../../atoms/global/users';
import NoData from '../../noData/NoData';
import { FilterResult, FilterTree, filterTreeLength } from '../../../components_v2/filter/model/Model';
import AdvancedFilters from '../../../components_v2/filter/AdvancedFilters';
import { FilterParameter } from '../../../components_v2/filter/pages/FilterList';
import { getCompanyFilters } from '../../client-companies/data/action';
import { reportErrorMessage } from './errorMessages';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import _ from 'lodash';
import { Period } from 'bindings/reports/generic/TimeRange';
import Switch from '../../../components_v2/Switch/Switch';

export const AllPeriods: string[] = ['Today', 'ThisWeek', '7DaysAgo', 'LastWeek', 'ThisMonth', '30DaysAgo', 'LastMonth', '90DaysAgo', 'ThisYear', '365DaysAgo', 'LastYear'];

const Tab = styled.div<{ selected?: boolean }>`
	padding: 5px 15px;
	border: 1px solid #ccc;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: ${p => p.selected ? BlueSidely : LightGreySidely2};
	cursor: pointer;
	color: ${p => p.selected ? LightGreySidely2 : 'black'};
	user-select: none;
`;

const ReportOption = styled(FlexDiv)`
	width: 100%;
	justify-content: space-between;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	height: 100%;
	width: 416px;
	flex-shrink: 0;
	background-color: white;
	padding: 10px 10px 20px 10px;
	border-radius: 10px;
`;

const InnerWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 10px;
	border-radius: 10px;
	overflow-y: auto;
	overflow-x: hidden;
`;

enum Views {
	Code,
	Visual
}

export const GenericEditorContext = React.createContext<{
	report: GenericReport
	setReport: (value: GenericReport) => void
		}>({ report: {} as GenericReport, setReport: () => undefined });

export function GenericEditorProvider(props: {report: string, setReport: (value: string) => void, children: React.ReactNode }): JSX.Element {
	const [report, setReport] = useFunctionState<GenericReport>(JSON.parse(props.report), ({ newValue }) => {
		props.setReport(JSON.stringify(newValue, null, 2));
		return newValue;
	});

	return <GenericEditorContext.Provider value={{ report, setReport }}>
		{props.children}
	</GenericEditorContext.Provider>;
}  

function getLastFromFirstPhysicalColumnGroup(report: GenericReport): number {
	const physicalColumns = ['AdditionalColumn', 'CompanyColumn', 'ProductColumn', 'InstanceColumn'];

	let lastPhysicalColumn = 0;
	report.panels[0].columns.forEach((column, index) => {
		if (typeof column !== 'string' && physicalColumns.find((physicalColumn) => physicalColumn in column)) {
			lastPhysicalColumn = index + 1;
		} else {
			return lastPhysicalColumn - 1;
		}
	});
	return lastPhysicalColumn;
}

function setVariation(report: GenericReport, value: Variation | undefined): GenericReport {
	report.variation = value;
	if (report.explode_by && report.new_group_by) {
		report.new_group_by -= 1;
	}
	if (report.variation !== undefined) {
		report.explode_by = undefined;
		report.new_group_by = getLastFromFirstPhysicalColumnGroup(report);
	}
	return { ...report };
}

function setEvolutionBy(report: GenericReport, value: Granularity | undefined): GenericReport {
	if (!value) {
		report.evolution = undefined;
	} else {
		report.evolution = value;
	}
	return { ...report };
}

const ReportTypes: DropdownData[] = [
	{ value: 'evolution', label: 'report_editor.evolution' },
	{ value: 'analyze', label: 'report_editor.analyze' },
];

export const DataSources: DropdownData[] = [
	{ value: 'Company', label: 'company' },
	{ value: 'User', label: 'user' },
	{ value: 'Instance', label: 'instance' },
];

function VisualEditor(props: {
	report: string | undefined
	setReport: (value: string) => void
}): JSX.Element
{
	if (!props.report) {
		props.setReport(JSON.stringify({}));
	}
	assert(props.report, 'report is undefined');
	return <GenericEditorProvider report={props.report} setReport={props.setReport}>
		<ReportFiltersProvider genericReport={JSON.parse(props.report)}>
			<_VisualEditor />
		</ReportFiltersProvider>
	</GenericEditorProvider>;
}

function addProduct(report: GenericReport, panelIndex: number, field: string) {
	const { products } = report.panels[panelIndex];

	if (!products) {
		report.panels[panelIndex].products = [field];
	} else {
		products.push(field);
	}
}

function removeProduct(report: GenericReport, panelIndex: number, field: string) {
	let products = report.panels[panelIndex]?.products;

	if (products) {
		products = products.filter((p: string) => p !== field);
		if (products.length === 0) {
			report.panels[panelIndex].products = undefined;
		} else {
			report.panels[panelIndex].products = products;
		}
	}
}

const EllipsisSpan = styled.span`
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ItalicSpan = styled.span`
	font-style: italic;
	color: ${DarkGreySidely2};
`;

export function Products(props: { products: string[] | undefined, panelIndex: number }): JSX.Element {
	const products = useRecoilValue(AProducts);
	const [modal, setModal] = React.useState(false);
	const { report, setReport } = React.useContext(GenericEditorContext);

	return <>
		<FlexDiv flow='column' gap='6px' align='stretch'>
			{props.products && props.products.map((product: string, index: number) =>
				<FlexDiv key={`${product}[${index}]`} justify='space-between' padding='0 10px'>
					<EllipsisSpan title={products.find(p => p.uuid === product)?.name}>{products.find(p => p.uuid === product)?.name}</EllipsisSpan>
					<img style={{ cursor: 'pointer' }} onClick={() => { removeProduct(report, props.panelIndex, product); setReport({ ...report }); }} height='25px' src={RemoveIcon} alt="" />
				</FlexDiv>
			)}
			<FlexDiv gap='6px' padding='10px 0px 0px 0px'><Add onClick={setModal} size='20px' />
				<ItalicSpan><Translate id='report_editor.add_product' /></ItalicSpan>
			</FlexDiv>
		</FlexDiv>
		<Popup isOpen={modal} popupMode={PopupMode.Details} onClickOut={() => setModal(false)} popupStyle={{ overflow: 'auto' }}
			content={<>
				<FlexDiv flow='column' justify='left' gap='8px' align='stretch' padding='15px'>
					<CrossImg margin='0 0 10px 0' src={close} onClick={() => setModal(false)} />
					{products.map((product) =>
					{
						if (props.products && props.products.includes(product.uuid)) {
							return null;
						}
						return <FlexDiv key={product.id} gap='6px'>
							<Add size='20px' onClick={() => { addProduct(report, props.panelIndex, product.uuid); setReport({ ...report }); }} />
							{product.name}
						</FlexDiv>;
					}
					)}
				</FlexDiv>
			</>} />
		
	</>;
}

const ReportWrapper = styled.div`
	background-color: white;
	padding: 10px;
	border-radius: 10px;
	overflow: auto;
	flex-shrink: 1;
`;

function countFilters(filters: FilterResult): number {
	const filterTree: FilterTree | undefined = filters?.formatted;

	return filterTree ? filterTreeLength(filterTree) : 0;
}

export function useSetFilters(setReport: (value: GenericReport) => void, report: GenericReport, filters: LocalFilters, owners, filterResult?: FilterResult) {
	React.useEffect(() => {
		if (filterResult) {
			setReport({ ...report, filter: filterResult });
		}
	}, [filterResult]);

	React.useEffect(() => {
		if (filters.created_by_filter) {
			if ((filters.created_by_filter as number[]).length === owners.length) {
				setReport({ ...report, created_by_filter: undefined });
			} else {
				setReport({ ...report, created_by_filter: (filters.created_by_filter as number[]) });
			}
		}
	}, [JSON.stringify(filters.created_by_filter)]);

	React.useEffect(() => {
		if (filters.form_filter) {

			if (filters.form_filter != undefined && (report.form_filter && report.form_filter.length !== (filters.form_filter as number[]).length) || filters.form_filter && (filters.form_filter as number[]).length > 0) {
				setReport({ ...report, form_filter: (filters.form_filter as number[]) });
			} else {
				setReport({ ...report, form_filter: undefined });
			}
		}
	}, [JSON.stringify(filters.form_filter)]);

	React.useEffect(() => {
		const timerange = filters.my_filters?.timerange;

		if (timerange) {
			setReport({ ...report, my_filters: { ...report.my_filters, timerange: timerange } });
		}
	}, [JSON.stringify(filters.my_filters?.timerange)]);

	React.useEffect(() => {
		const tags = filters.my_filters?.tags;
		if (filters.my_filters) {

			if (tags && tags.length > 0) {
				setReport({ ...report, my_filters: { ...report.my_filters, tags: filters.my_filters?.tags } });
			}
			else {
				setReport({ ...report, my_filters: { ...report.my_filters, tags: undefined } });
			}
		}
	}, [JSON.stringify(filters.my_filters?.tags)]);

	React.useEffect(() => {
		const localProducts = filters.my_filters?.products?.products;
		if (localProducts && localProducts.length > 0) {
			if (filters.my_filters?.products?.all) {
				setReport({ ...report, my_filters: { ...report.my_filters, products: { all: true, products: [] } } });
			} else {
				setReport({ ...report, my_filters: { ...report.my_filters, products: { all: filters.my_filters?.products?.all ?? true, products: localProducts } } });
			}
		}
		else {
			if (filters.my_filters && filters.my_filters.products)
				setReport({ ...report, my_filters: { ...report.my_filters, products: { all: filters.my_filters.products.all, products: [] } } });
		}
	}, [JSON.stringify(filters.my_filters?.products?.products), JSON.stringify(filters.my_filters?.products?.all)]);

	React.useEffect(() => {
		const brands = filters.my_filters?.brands?.brands;

		if (brands && brands.length > 0) {
			if (filters.my_filters?.brands?.all) {
				setReport({ ...report, my_filters: { ...report.my_filters, brands: undefined } });
			} else {
				setReport({ ...report, my_filters: { ...report.my_filters, brands: { brands, all: filters.my_filters?.brands?.all ?? true } } });
			}
		}
		else {
			if (filters.my_filters && filters.my_filters.brands)
				setReport({ ...report, my_filters: { ...report.my_filters, brands: { brands: [], all: filters.my_filters.brands.all } } });
		}

	}, [JSON.stringify(filters.my_filters?.brands)]);
}

function _VisualEditor(): JSX.Element {
	const VentilationTypes: DropdownData[] = [
		{ value: 'year', label: translateToString('year') },
		{ value: 'quarter', label: translateToString('quarter') },
		{ value: 'month', label: translateToString('month') },
		{ value: 'week', label: translateToString('week') },
		{ value: 'day', label: translateToString('day') },
	];

	const VariationTypes: DropdownData[] = [
		{ value: 'N1', label: translateToString('report_editor.options.N1') },
		{ value: 'P0', label: translateToString('report_editor.options.P0') },
		{ value: undefined, label: translateToString('report_editor.options.none') }
	];
	const BeginPeriods: Period[] = ['Today', 'ThisWeek', '7DaysAgo', 'LastWeek', 'ThisMonth', '30DaysAgo', 'LastMonth', 'ThisYear', '365DaysAgo', 'LastYear'];
	const EndPeriods: Period[] = ['ThisWeek', '7DaysAgo', 'LastWeek', 'ThisMonth', '30DaysAgo', 'LastMonth', '90DaysAgo', 'ThisYear', '365DaysAgo', 'LastYear'];

	const BeginPeriodTypes: DropdownData[] = [];
	const EndPeriodTypes: DropdownData[] = [];
	BeginPeriodTypes.push({ value: undefined, label: translateToString('report_editor.period.begin_default') });
	EndPeriodTypes.push({ value: undefined, label: translateToString('report_editor.period.end_default') });
	
	BeginPeriods.map(e => (BeginPeriodTypes.push({ value: e, label: translateToString('report_editor.period.' + e) })));
	EndPeriods.map(e => (EndPeriodTypes.push({ value: e, label: translateToString('report_editor.period.' + e) })));


	const { report, setReport } = React.useContext(GenericEditorContext);
	const [reportType, setReportType] = React.useState<DropdownData>(report.evolution ? { value: 'evolution', label: 'evolution' } : { value: 'analyze', label: 'analyze' });
	const [reportResult, setReportResult] = React.useState<Report>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const { height } = useWindowDimensions();
	const { filters, jsxFilters } = React.useContext(ReportFiltersContext);
	const owners = useRecoilValue(AUsers);
	const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterResult, setFilterResult] = React.useState<FilterResult>(filters.filter as FilterResult);
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
	const [isByProduct, setIsByProduct] = React.useState<boolean>(false);

	React.useEffect(() => {
		getCompanyFilters().then(response => setFilterParams(response.filters));
	}, []);
	
	const products = useRecoilValue(AProducts);
	const [, setFinalyFilteredProducts] = React.useState<string[] | undefined>();
	
	useSetFilters(setReport, report, filters, owners, filterResult);

	React.useEffect(() => {
		if (report.panels.some(p => p.columns.some(c => typeof c !== 'string' && 'EventColumn' in c)) && report.group_by !== 'Instance') {
			setReport({ ...report, sum: false, group_by: 'Instance' });
		}
	}, [JSON.stringify(report.panels)]);

	React.useEffect(() => {
		if (reportType.value === 'evolution') {
			const newReport = _.cloneDeep(report);
			const lines = newReport.new_group_by;
			if (!newReport.panels[0].columns.includes('Index')) {
				if (newReport.panels[0].columns.length === 0) {
				} else if (newReport.panels[0].columns.length === 1) {
					newReport.panels[0].columns = ['Index'];
				} else {
					const newColumns = [
						...newReport.panels[0].columns.slice(0, lines),
						'Index' ,
						...newReport.panels[0].columns.slice(lines)
					];
					
				}

				newReport.panels[0].columns = newColumns;
			}
			newReport.variation = undefined;
			newReport.evolution = report.evolution ?? 'month';
			newReport.sliding = false;
			if (newReport.new_group_by) {
				newReport.explode_by = newReport.new_group_by;
			} else {
				newReport.new_group_by = 1;
				newReport.explode_by = 1;
			}
			setReport(newReport);

		} else {
			const newReport = _.cloneDeep(report);

			newReport.panels[0].columns = newReport.panels[0].columns.filter((column) => column !== 'Index');
			newReport.explode_by = undefined;
			newReport.sliding = undefined;
			newReport.evolution = undefined;
			setReport(newReport);
		}
	}, [reportType]);

	React.useEffect(() => {
		if (report.group_by === 'Company') {
			report.panels[0].columns.forEach((column, index) => {
				if (typeof column !== 'string' && 'UserColumn' in column) {
					report.panels[0].columns[index] = { CompanyColumn: column.UserColumn };
				}
			});
		}
		else if (report.group_by === 'User') {
			report.panels[0].columns.forEach((column, index) => {
				if (typeof column !== 'string' && 'CompanyColumn' in column) {
					if (column.CompanyColumn === 'Owner') {
						column.CompanyColumn = 'Id';
					}
					report.panels[0].columns[index] = { UserColumn: column.CompanyColumn };
				}
			});
		}
		setReport(report);
	}, [report.group_by]);

	React.useEffect(() => {
		setIsByProduct(report.panels.some(panel => panel.columns.some(column => typeof column !== 'string' && 'ProductColumn' in column)));
	}, [JSON.stringify(report.panels)]);

	React.useEffect(() => {
		if (JSON.stringify(filters) !== '{}') {
			let finaly: Product[] | undefined = undefined;
			if (filters.my_filters?.tags && filters.my_filters.tags.length != 0) {
				finaly = filterProducts(filters.my_filters.tags, products);
			}
			if (filters.my_filters && filters.my_filters.brands && !filters.my_filters.brands.all) {
				if (finaly) {
					finaly = finaly.filter(e => filters.my_filters?.brands?.brands?.some(b => b == e.brand));
				} else {
					finaly = products.filter(e => filters.my_filters?.brands?.brands?.some(b => b == e.brand));
				}
			}
			if (filters.my_filters?.products && !filters.my_filters?.products?.all) {
				if (finaly) {
					finaly = finaly.filter(e => filters.my_filters?.products?.products.some(p => p == e.id));
				} else {
					finaly = products.filter(e => filters.my_filters?.products?.products?.some(p => p == e.id));
				}
			}
			setFinalyFilteredProducts(finaly?.map(e => e.uuid));
			setLoadingState(LoadingStateEnum.LOADING);
			getGenericAndDecodeReport({
				...report,
				range: { begin: (report.range && report.range.begin) ? report.range.begin : '90DaysAgo', end: (report.range && report.range.end) ? report.range.end : 'Today' },
				...filters,
				evolution: report.evolution,
				variation: report.variation,
				product_filter: finaly?.map(e => e.uuid),
				filter: (report.filter)?.formatted,
			}).then(result => {
				setReportResult(result);
				setLoadingState(LoadingStateEnum.LOADED);
			}).catch(e => {
				const decoder = new TextDecoder('utf-8');
				setLoadingState(LoadingStateEnum.ERROR);
				if (e.response?.data) {
					setErrorMessage(decoder.decode(new Uint8Array(e.response?.data)));
				} else {
					setErrorMessage(e.message);
				}
			});
		}
	}, [JSON.stringify(report)]);

	const alert = useAlert();
	const data_sources: DropdownData<GroupBy>[] = DataSources.map(e => ({ ...e, label: typeof e.label === 'string' ? translateToString('report_editor.data_source.' + e.label) : e.label }));
	const report_types: DropdownData<string>[] = ReportTypes.map(e => ({ ...e, label: typeof e.label === 'string' ? translateToString(e.label) : e.label }));
	const slidingOptions: DropdownData<boolean>[] = [{ label: translateToString('report_editor.sliding_dropdown.true'), value: true }, { label: translateToString('report_editor.sliding_dropdown.false'), value: false }];
	const sumOptions: DropdownData<boolean>[] = [{ label: translateToString('report_editor.sum_dropdown.true'), value: true }, { label: translateToString('report_editor.sum_dropdown.false'), value: false }];
	return <>
		{filterParams && <AdvancedFilters
			permission='ReadCompany'
			isOpen={filterOpen}
			setOpen={setFilterOpen}
			isOpenSummary={true}
			filterList={filterParams}
			filterValues={filterResult?.values}
			onChange={(value) => setFilterResult(value)}
		/>}
		<FlexDiv gap='10px' height='100%'>
			<Wrapper>
				<InnerWrapper>
					<TitleAndChild
						margin='0 30 0 0'
						addDiv
						defaultOpen={true}
						localCollapseStyle={{ justifyContent: 'left' }}
						title={translateToString('report_editor.report_options')}>
						<OptionsModal>
							<ReportOption>
								<Translate id='report_editor.period.title' /> début
								<Dropdown
									name='range'
									dropdownStyle={{ width: '200px', optionWidth: '200px' }}
									datalist={BeginPeriodTypes}
									selectedValue={BeginPeriodTypes.find(a => {
										if (report.range?.begin && report.range?.begin !== '90DaysAgo') {
											return a.value === report.range?.begin;
										} else {
											return a.value === undefined;
										}
									})
									}
									onChange={value => {
										setReport({ ...report, range: { begin: value.value ?? '90DaysAgo', end: report.range?.end ?? 'Today' } });
									}}
								/>
							</ReportOption>
							<ReportOption>
								<Translate id='report_editor.period.title' /> fin
								<Dropdown
									name='range'
									dropdownStyle={{ width: '200px', optionWidth: '200px' }}
									datalist={EndPeriodTypes}
									selectedValue={EndPeriodTypes.find(a => {
										if (report.range?.begin && report.range?.end !== 'Today') {
											return a.value === report.range?.end;
										} else {
											return a.value === undefined;
										}
									})
									}
									onChange={value => {
										setReport({ ...report, range: { end: value.value ?? 'Today', begin: report.range?.begin ?? '90DaysAgo' } });
									}}
								/>
							</ReportOption>
							<ReportOption>
								<Translate id='report_editor.report_type' />
								<Dropdown datalist={report_types} name='type' selectedValue={report_types.find(a => a.value === reportType.value)} onChange={value => setReportType(value)} />
							</ReportOption>
							<ReportOption>
								<Translate id='report_editor.data_source.title' />
								<Dropdown datalist={data_sources}
									dropdownStyle={{ width:'200px', optionWidth: '200px' }}
									name='source'
									selectedValue={(data_sources.find(a => a.value === report.group_by))}
									onChange={value => {
										if (value.value === 'Instance')
											setReport({ ...report, group_by: value.value, sum: false });
										else
											setReport({ ...report, group_by: value.value });
									}} />
							</ReportOption>
							{reportType && reportType.value === 'evolution' && <ReportOption>
								<Translate id='report_editor.default_ventilation' /><Dropdown datalist={VentilationTypes} name='evolution' selectedValue={VentilationTypes.find(a => a.value === report.evolution) ?? { value: 'month', label: 'Mois' }} onChange={value => setReport(setEvolutionBy(report, value.value))} />
							</ReportOption>}
							{report.group_by !== 'Instance' && <ReportOption>
								<Translate id='report_editor.sum_dropdown.title' />
								<Dropdown datalist={sumOptions}
									dropdownStyle={{ width:'200px', optionWidth: '200px' }}
									name='source'
									selectedValue={report.sum !== undefined ? (sumOptions.find(a => a.value === report.sum)) : (sumOptions.find(a => a.value === false))}
									onChange={value => {
										setReport({ ...report, sum: value.value });
									}} />
							</ReportOption>}
							{reportType && reportType.value === 'evolution' && <ReportOption>
								<Translate id='report_editor.sliding_dropdown.title' />
								<Dropdown datalist={slidingOptions}
									dropdownStyle={{ width:'200px', optionWidth: '200px' }}
									name='source'
									selectedValue={report.sliding !== undefined ? (slidingOptions.find(a => a.value === report.sliding)) : (slidingOptions.find(a => a.value === true))}
									onChange={value => {
										setReport({ ...report, sliding: value.value });
									}} />
							</ReportOption>}
							{reportType && reportType.value === 'analyze' && <ReportOption>
								<Translate id='report_editor.options.show_variation' />
								<Dropdown datalist={VariationTypes}
									dropdownStyle={{ width:'200px', optionWidth: '200px' }}
									name='variation'
									selectedValue={report.variation ? (VariationTypes.find(a => a.value === report.variation)) : { value: undefined, label: translateToString('report_editor.options.none') }}
									onChange={value => {
										if (value.value) {
											alert({
												title: translateToString('warning'),
												content: 'Si vous activez la variation, certaines valeurs peuvent être transformés en lignes, notamment les champs de société, d\'instances, de produits, ainsi que les champs additionnels.',
											}).then(res => {
												if (res === AlertRes.Ok) {
													setReport(setVariation(report, value.value));
												}
											});
										}
										else {
											setReport(setVariation(report, value.value));
										}
									}}
								/>
							</ReportOption>}
							{isByProduct && <ReportOption>
								<Translate id='report_editor.force_products' />
								<Switch value={report.force_all_products} onChange={v => setReport({ ...report, force_all_products: v })}/>
							</ReportOption>}
						</OptionsModal>
					</TitleAndChild>
					<TitleAndChild
						title={translateToString('report_editor.report_lines')}
						margin='0 30 0 0'
						addDiv
						defaultOpen={true}
						localCollapseStyle={{ justifyContent: 'left' }}
					>
						<>
							<EditorWrapper>
								<RecursiveReportEditor only_lines />
							</EditorWrapper>
						</>
					</TitleAndChild>
					{!report.variation && <TitleAndChild
						margin='0 30 0 0'
						addDiv
						defaultOpen={true}
						localCollapseStyle={{ justifyContent: 'left' }}
						title={translateToString('report_editor.report_columns')}>
						<EditorWrapper>
							<RecursiveReportEditor only_columns />
						</EditorWrapper>
					</TitleAndChild>}
					<TitleAndChild
						margin='0 30 0 0'
						addDiv
						defaultOpen={true}
						localCollapseStyle={{ justifyContent: 'left' }}
						title={translateToString('report_editor.report_values')}>
						<EditorWrapper>
							<RecursiveReportEditor />
						</EditorWrapper>
					</TitleAndChild>
					<TitleAndChild
						margin='0 30 0 0'
						addDiv
						defaultOpen={true}
						localCollapseStyle={{ justifyContent: 'left' }}
						title={translateToString('report_editor.filters')}>
						<OptionsModal gap='16px'>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<Translate id='report_editor.filter_categories.form_instances'/>
								{jsxFilters && jsxFilters[0] && jsxFilters[0][1].child}
							</FlexDiv>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<Translate id='report_editor.filter_categories.form_models'/>
								{jsxFilters && jsxFilters[1] && jsxFilters[1][1].child}
							</FlexDiv>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<Translate id='report_editor.filter_categories.form_creator'/>
								{jsxFilters && jsxFilters[1] && jsxFilters[1][0].child}
							</FlexDiv>
							<FlexDiv flow='column' align='stretch'>
								<div style={{ padding:'0 0 8px 0' }}><Translate id='report_editor.filter_categories.product_categories'/></div>
								{jsxFilters && jsxFilters[2] && jsxFilters[2][0].child}
							</FlexDiv>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<Translate id='report_editor.filter_categories.brands'/>
								{jsxFilters && jsxFilters[2] && jsxFilters[2][1].child}
							</FlexDiv>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<Translate id='report_editor.filter_categories.product_tags'/>
								{jsxFilters && jsxFilters[2] && jsxFilters[2][2].child}
							</FlexDiv>
							<div>
								<DefaultButton margin='0' onClick={() => setFilterOpen(true)}><Translate id="global.filter.company_filters" /> ({countFilters(filterResult)})</DefaultButton>
							</div>
						</OptionsModal>
					</TitleAndChild>
				</InnerWrapper>
			</Wrapper>
			{loadingState === LoadingStateEnum.ERROR ? 
				<NoData message={reportErrorMessage(errorMessage)} isError={true} height='100%' borderRadius='10px'></NoData> : 
				<ReportWrapper>
					<ComponentLoader loadingState={loadingState} allScreen />
					{reportResult && <ReportInterpretor
						report={reportResult}
						setToolBarState={() => ({})}
						height={height - 283}
						onCompanyClick={() => ({})}
						onFormInstanceClick={() => ({})}
					/>}
				</ReportWrapper>}
		</FlexDiv>
	</>;
}

const UnderlineBefore = styled.div`
	position: relative;
	padding: 4px 0px;
	::before {
		position: absolute;
		left: 15px;
		bottom: 0;
		width: 75%;
		height: 1px;
		content: '';
	}
	&:hover::before {
		background-color: ${BlueSidely};
	}
`;

const TitleInput = styled.input<{ width?: number }>`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-left: 15px;
	border: none;
	width: ${p => p.width ? p.width + p.width * 0.1 + 'ch' : '100%'};
	max-width: 80vw;
	min-width: 100px;
`;

const ContentWrapper = styled.div`
	width: 100%;
	height: calc(100vh - 130px);
	font-size: 13px;
	border-radius: 10px;
	border-radius: 5px;
	box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.01);
`;

export default function GenericEditor(props: ComponentProps): JSX.Element
{
	const history = useHistory();
	const [localFnReport, setLocalFnReport] = React.useState<LocalStorageReport | undefined>(getLocalStorage('current-report'));
	const [reportText, setReportText] = React.useState<string | undefined>(JSON.stringify(localFnReport?.report?.report, null, 2));
	const [currentView, setCurrentView] = React.useState<number>(Views.Visual);
	const [reportTitle, setReportTitle] = React.useState<string | undefined>(localFnReport?.report?.name);
	
	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('report_editor.title'),
			bottomLeftToolbarComponent: <UnderlineBefore>
				<TitleInput type='text' value={reportTitle} onChange={(e) => setReportTitle(e.target.value)} width={reportTitle?.length}></TitleInput>
			</UnderlineBefore>,
			bottomRightToolbarComponent:
				<FlexDiv flexWrap='nowrap'>
					<DefaultButton onClick={() => {
						if (localFnReport && localFnReport.report && reportText) {
							putReport(reportTitle?.trim() ?? localFnReport.report.name, JSON.parse(reportText), localFnReport.report.id).then(
								() => {
									if (localFnReport && localFnReport.report) {
										getReportById(localFnReport.report.id).then(setLocalFnReport);
										localStorage.setItem('reportKind', localFnReport.report.id.toString());
									}
									history.push('/reports', { from: 'editor' });
								}
							);
						}
					}} height="5%" disabled={(reportText === JSON.stringify(localFnReport?.report?.report, null, 2)) && localFnReport?.report?.name === reportTitle}><Translate id='save' />
					</DefaultButton>
					<CrossClose src={close} size='28px' onClick={() => history.push({ pathname: 'reports' })} />
				</FlexDiv>,
		});
	}, [reportText, reportTitle]);


	return <>
		<ContentWrapper>
			<RestrictedSuperAdmin>
				<FlexDiv padding='0px 0px 10px 10px'>
					<Tab selected={currentView === Views.Code} onClick={() => setCurrentView(Views.Code)} ><Translate id='report_editor.code_editor' /></Tab>
					<Tab selected={currentView === Views.Visual} onClick={() => setCurrentView(Views.Visual)}><Translate id='report_editor.visual_editor' /></Tab>
				</FlexDiv>
			</RestrictedSuperAdmin>
			{currentView === Views.Visual &&
				<VisualEditor report={reportText} setReport={setReportText} />
			}
			{currentView === Views.Code && <Editor
				height="85%"
				defaultLanguage='json'
				defaultValue={reportText}
				onChange={setReportText}
			/>}
		</ContentWrapper>
	</>;
}