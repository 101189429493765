import close from 'images/icons/orders/close.svg';
import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import * as _ from 'lodash';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import { Checkbox } from '../../../components_v2/filterList/style/Style';
import InputSearch from '../../../components_v2/input/InputSearch';
import Popup from '../../../components_v2/popup/Popup';
import { DandDDots } from '../../../containers_v2/settings/subSettings/statusesAndTagsSettings';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { Open } from '../../../styles/global/css/Open';
import { BorderColor, DarkGreySidely2, GreySidely2, LightGreySidely2 } from '../../../styles/global/css/Utils';
import { Translate, translateToString } from '../../../styles/global/translate';
import { FlexDiv } from '../../products/style';
import usePermission from '../../permissions/usePermission';

export type Column = {
	label: string;
	value: string;
	checked: boolean;
	sequence: number;
	categoryIndex: number | undefined;
}

const Wrapper = styled.div`
	cursor: default;
`;

const SettingsImg = styled.img`
	width: 18px;
	cursor: pointer;
`;

const PopupWrapper = styled.div`
	display: grid;
	grid-template-rows: 30px 1fr 50px;
	grid-gap: 10px;
	height: 100%;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	height: 100%;
	min-height: 0;
	padding-right: 10px;
	font-weight: 400;
`;

const GridElement = styled.div<{index: number}>`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 10px;
	user-select: none;
	height: 100%;
	min-height: 0;
	padding-left: 10px;
	padding-right: ${p => p.index === 0 ? '8px' : '0'};
	border-bottom: 2px solid ${BorderColor};
	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 2px;
		height: 93%;
		background-color: ${p => p.index === 0 ? BorderColor : 'transparent'};
	}
	@media (max-width: 928px) {
		&::before {
			display: none;
		}
		padding: 10px;
		padding-top: ${p => p.index === 1 ? '20px' : '10px'};
	}
`;

const Title = styled.h1`
	font-size: 15px;
	font-weight: 600;
	padding-left: 10px;
`;

const LocalCollapse = styled(Collapse)`
	width: inherit;
	display: flex;
	&:not(.show) &:collapse {
		display: none;
	}
`;

const CollapseTitle = styled.div`
	font-size: 14px;
	font-weight: 500;
	padding: 10px 0;
`;

const SelectedColumn = styled.div`
	display: flex;
	align-items: center;
	height: 40px;
	min-height: 40px;
	width: 100%;
	padding-left: 10px;
	border-radius: 5px;
	border: 1px solid ${BorderColor};
	background-color: white;
	color: ${DarkGreySidely2};
`;

const CrossClose = styled.img`
	cursor: pointer;
	aspect-ratio: 1 / 1;
	width: 22px;
`;

function TitleAndChild(props: { title: string, children: React.ReactNode }) {
	const [isOpen, setIsOpen] = React.useState(true);
	return <>
		<FlexDiv justify='space-between' padding='0 10px 0 0' height='30px' cursor='pointer' onClick={() => setIsOpen(!isOpen)}>
			<CollapseTitle>{props.title}</CollapseTitle>
			<Open isOpen={isOpen}/>
		</FlexDiv>
		<div><LocalCollapse isOpen={isOpen}>{props.children}</LocalCollapse></div>
	</>;
}

const DialogPopup = styled.dialog`
	width: 70vw;
	height: 75vh;
	padding: 25px 15px 10px 25px;
	border-radius: 10px;
	position: fixed;
	border: none;
	display: none;
	opacity: 0;
	transition-property: overlay display opacity;
	transition-duration: 0.2s;
	transition-behavior: allow-discrete;
	&::backdrop {
		transition-property: overlay display opacity;
		transition-duration: 0.2s;
		transition-behavior: allow-discrete;
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}
	&[open] {
		display: block;
		opacity: 1;
	}
	&[open]::backdrop {
		opacity: 1;
	}
	@starting-style {
		&[open], &[open]::backdrop {
			opacity: 0;
		}
	}
`;

export function ColumnOrganizer(props: {
	columns: Column[],
	categories: readonly string[],
	onSort: (values: string[]) => void,
	setHiddenColumns: (values: string[]) => void,
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [columnsByCategory, setColumnsByCategory] = React.useState<{category: string, columns: Column[]}[]>([]);
	const sortedSelectedColumns = React.useMemo(() => columnsByCategory.flatMap(v => v.columns).filter(v => v.checked).sort((a, b) => a.sequence - b.sequence), [columnsByCategory]);
	const [selectAll, setSelectAll] = React.useState(columnsByCategory.flatMap(v => v.columns).every(v => v.checked));
	const ref = React.useRef<HTMLDialogElement | null>(null);
	const wrapperRef = React.useRef<HTMLDivElement | null>(null);
	const hasShelfAudit = usePermission({ objectAction: 'ReadShelfAudit' });

	function onDragEnd({ source, destination }) {
		if (!destination || !source || source.index === destination.index) return;

		const result = columnsByCategory.flatMap(v => v.columns).sort((a, b) => a.sequence - b.sequence);
		const [removed] = result.splice(source.index, 1);
		result.splice(destination.index, 0, removed);
		result.forEach((r, i) => r.sequence = i);
		setColumnsByCategory([ ...columnsByCategory ]);
	}

	React.useEffect(() => {
		setSelectAll(columnsByCategory.flatMap(v => v.columns).every(v => v.checked));
	}, [columnsByCategory]);

	React.useEffect(() => {
		setInputValue('');
		setSelectAll(columnsByCategory.flatMap(v => v.columns).every(v => v.checked));
		const result = props.categories?.map(category => ({ category, columns: [] as Column[] })) ?? [];
		result.push({ category: 'other', columns: [] });
		props.columns?.forEach(c => {
			if (c.categoryIndex === undefined) result[result.length - 1].columns.push(_.cloneDeep(c));
			else result[c.categoryIndex].columns.push(_.cloneDeep(c));
		});
		if (!hasShelfAudit) {
			const shelfAuditIndex = result.findIndex(v => v.category === 'shelf_audit');
			if (shelfAuditIndex !== -1) result.splice(shelfAuditIndex, 1);
		}

		setColumnsByCategory(result);
	}, [props.columns, isOpen]);

	React.useEffect(() => {
		if (isOpen) {
			const handleEsc = (e: KeyboardEvent) => {
				if (e.key === 'Escape') setIsOpen(false);
			};
			const handleClickOutside = (e: MouseEvent) => {
				if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
					setIsOpen(false);
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			document.addEventListener('keydown', handleEsc);
			ref.current?.showModal();
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
				document.removeEventListener('keydown', handleEsc);
			};
		}
		else 
			ref.current?.close();
	}, [isOpen]);

	return (
		<Wrapper>
			<SettingsImg src={system_setting_icon} onClick={() => setIsOpen(!isOpen)} />
			<DialogPopup innerRef={React.useCallback(node => ref.current = node, [])}>
				<PopupWrapper innerRef={React.useCallback(node => wrapperRef.current = node, [])}>
					<Title><Translate id='company.column_organizer.title' /></Title>
					<GridContainer>
						<GridElement index={0}>
							<InputSearch name='search-views' type='text' value={inputValue} onChange={(value) => setInputValue(value)} placeholder={translateToString('company.column_organizer.search_columns')} inputStyle={
								{
									backgroundColor: LightGreySidely2,
									border: 'none',
									borderRadius: '7px',
									width: '240px',
									padding: '5px 10px',
									placeholderColor: GreySidely2,
								}
							}/>
							<FlexDiv flow='column' align='stretch' height='100%' overflow='auto' scrollGutter={true} padding='10px 0'>
								<FlexDiv gap='8px' cursor='pointer' color={DarkGreySidely2} padding='0 0 10px 0' onClick={() => {
									const val = !selectAll;
									setSelectAll(val);
									columnsByCategory.flatMap(v => v.columns).forEach(v => v.checked = val);
									setColumnsByCategory([ ...columnsByCategory ]);
								}}>
									<Checkbox isActive={selectAll}/>
									<Translate id='company.column_organizer.select_all'/>
								</FlexDiv>
								{columnsByCategory.map((category, i) => {
									if (category.columns.length === 0) return null;
									const filteredColumns = category.columns.filter(v => v.label.toLowerCase().includes(inputValue.toLowerCase()));
									if (filteredColumns.length === 0) return null;
									return <TitleAndChild key={category.category} title={category.category === 'additional_columns' ? translateToString('additional_and_report_fields') : translateToString(category.category)}>
										<FlexDiv flow='column' align='stretch' gap='8px' padding='6px 0 10px 0'>
											{filteredColumns.map((column, y) => {
												return <FlexDiv gap='8px' cursor='pointer' color={DarkGreySidely2} key={y} onClick={(e) => {
													e.stopPropagation();
													columnsByCategory[i].columns[y].checked = !column.checked;
													setColumnsByCategory([ ...columnsByCategory ]);
												}} ><Checkbox isActive={column.checked}/><div>{column.label}</div></FlexDiv>;
											})}
										</FlexDiv>
									</TitleAndChild>;})}
							</FlexDiv>
						</GridElement>
						<GridElement index={1}>
							<div style={{ fontSize: '14px', fontWeight: '500', paddingLeft: '10px' }}><Translate id='company.column_organizer.selected_columns' /> ({sortedSelectedColumns.length})</div>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId='companies'>
									{(provided): React.ReactElement<HTMLElement> => (
										<FlexDiv {...provided.droppableProps} innerRef={provided.innerRef} className="companies" flow='column' align='stretch' gap='10px' overflow='auto' scrollGutter padding='10px 4px 10px 0px'>
											{sortedSelectedColumns.map((column) =>
												<Draggable key={column.value} draggableId={column.value} index={column.sequence}>
													{(provided): React.ReactElement<HTMLElement> => (
														<FlexDiv width='100%' gap='6px' innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<DandDDots />
															<SelectedColumn>
																{column.label}
															</SelectedColumn>
															<CrossClose src={close} onClick={() => {
																const toClose = columnsByCategory.flatMap(v => v.columns).find(v => v.value === column.value);
																if (toClose) {
																	toClose.checked = false;
																	setColumnsByCategory([ ...columnsByCategory ]);
																}
															}}/>
														</FlexDiv>
													)}
												</Draggable>
											)}
											{provided.placeholder}
										</FlexDiv>
									)}
								</Droppable>
							</DragDropContext>
						</GridElement>
					</GridContainer>
					<FlexDiv justify='flex-end'>
						<FlexDiv>
							<DefaultButton buttonStyle={2} height='38px' width='130px' onClick={() => setIsOpen(false)}><Translate id='cancel'/></DefaultButton>
							<DefaultButton height='38px' width='130px' onClick={() => {
								const columns = columnsByCategory.flatMap(v => v.columns);
								props.onSort(columns.sort((a, b) => a.sequence - b.sequence).map((col) => col.value));
								props.setHiddenColumns(columns.filter(v => !v.checked).map(v => v.value));
								setIsOpen(false);
							}}><Translate id='apply'/></DefaultButton>
						</FlexDiv>
					</FlexDiv>
				</PopupWrapper>
			</DialogPopup>
		</Wrapper>
	);
}
