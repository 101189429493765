import * as React from 'react';
import ReportInterpretor from './interpretor/ReportInterpretor';
import { decodeReport, Report } from './interpretor/bareReportingDecoder';
import { useWindowDimensions } from '../../components_v2/utils';
import { useHistory } from 'react-router';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { URL_FOUNDATION } from '../../config/keys';
import axios from 'axios';
import styled from 'styled-components';

const ReportTitle = styled.h1`
	margin: 0;
	font-weight: 600;
	font-size: 20px;
`;

const ReportWrapper = styled.div`
	display: flex;
	flex-flow: column;
	gap: 30px;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background-color: #ecf6fb;
	padding: 40px;
`;

const InnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 20px;
	box-shadow: 5px 5px 44px -3px rgba(0,0,0,0.06);
	border-radius: 10px;
	max-width: calc(100% - 40px);
	overflow: hidden;
`;

export default function ReportView() {
	const { height } = useWindowDimensions();
	const history = useHistory();
	const [token, setToken] = React.useState<string>();
	const [report, setReport] = React.useState<Report>();
	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [title, setTitle] = React.useState<string>();

	function checkUrlParams() {
		if (window.location.hash) {
			const hash = window.location.hash;
			const endOfHash = hash.indexOf('?');
			const newToken = hash.slice(1, endOfHash);
			if (token === newToken) return;
			setToken(newToken);
			setTitle(decodeURIComponent(hash.slice(endOfHash + 7)));
		}
	}

	React.useEffect(() => {
		if (!token) return;
		axios.post(`${URL_FOUNDATION}/unique-token/report`,
			{},
			{
				responseType: 'arraybuffer',
				headers: {
					common: {
						Authorization: `Bearer ${token}`
					}
				}
			}).then(res => {
			const u8Array = new Uint8Array(res.data);
			setReport(decodeReport(u8Array));
			setLoading(LoadingStateEnum.LOADED);
		});
	}, [token]);

	React.useEffect(() => {
		checkUrlParams();
		history.listen(checkUrlParams);
	}, [history]);

	if (!token) return <>No token</>;
	if (!report && loading == LoadingStateEnum.LOADED) return <>No report</>;

	return <>
		{report && <ReportWrapper>
			{title && <ReportTitle>{title}</ReportTitle>}
			<InnerWrapper>
				<ReportInterpretor setToolBarState={() => undefined}
					report={report} height={height - 265} onCompanyClick={() => undefined} onFormInstanceClick={() => undefined} />
			</InnerWrapper>
		</ReportWrapper> }
		<ComponentLoader loadingState={loading} />
	</>;
}